import { css } from '@emotion/core';
import { dimensions, fonts, colors, breakpoints } from './variables';
import { getEmSize } from './mixins';

export default theme => css`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${dimensions.fontSize.regular}px !important;
    line-height: ${dimensions.lineHeight.regular} !important;
  }

  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${fonts.sansSerif};
    color: ${theme.colors.primary};
    background-color: ${theme.colors.background};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  a {
    color: ${theme.colors.accent};
    text-decoration: none;

    &:visited {
      color: ${theme.colors.accent};
      opacity: 0.85;
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    position: relative;
    line-height: 1.65;
    color: ${theme.colors.grey};

    font-size: 0.95em;

    width: 100%;
    max-width: 1004px;
    overflow: hidden;

    border-collapse: separate;
    border-spacing: 0;
    background: ${theme.colors.card};
  }

  tbody {
    border-collapse: collapse;
  }

  thead {
    color: ${theme.colors.primary};
    text-align: left;
    border-collapse: collapse;
    position: relative;
    line-height: 1.756;
    font-weight: 600;
    font-family: ${fonts.serif};
  }

  td {
    border-top: 1px solid ${theme.colors.horizontalRule};
  }

  th {
    border-bottom: 1px solid ${theme.colors.horizontalRule};
  }

  tbody tr:first-of-type td {
    border-top: none;
  }

  td,
  th {
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    font-size: 15.5px;
    background: ${theme.colors.card};
    border-collapse: collapse;
    color: ${theme.colors.grey};
  }

  th,
  thead td {
    padding: 0.3rem 0.5rem;
    font-size: 16px;
    background: ${theme.colors.tableHeading};
    color: ${theme.colors.primary};
  }

  tfoot td {
    border-top: 1px solid ${theme.colors.horizontalRule};
    background-color: ${theme.colors.tableFooter};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.414rem;
    margin-bottom: 0.5rem;
    color: ${theme.colors.primary};
    font-weight: bold;
    font-family: ${fonts.serif};
    text-rendering: optimizeLegibility;
  }

  h1 {
    word-break: keep-all;
    font-size: 45px;
    line-height: 1.15;
    margin-bottom: 0.6rem;
  }

  h2 {
    word-break: keep-all;
    font-size: 32px;
    line-height: 1.333;
  }

  h3 {
    word-break: keep-all;
    font-size: 24px;
    line-height: 1.45;
  }

  h4,
  h5,
  h6 {
    word-break: keep-all;
    font-size: 18px;
    line-height: 1.45;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;

    font-size: 17px;
    color: ${theme.colors.articleText};
    font-family: ${fonts.sansSerif};
    width: 100%;
    b {
      font-weight: 800;
    }
  }

  strong {
    color: ${theme.colors.black};
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: 0.5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.ui.light};
  }

  blockquote {
    margin: 0.8rem 0;
    padding: 0.5rem 1rem;
    border-left: 0.25rem solid ${colors.ui.light};
    color: ${colors.gray.calm};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${getEmSize(breakpoints.md)}em) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }
`;
