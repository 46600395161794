import * as React from 'react';
import styled from '@emotion/styled';
import Container from './Container';
import { getEmSize } from '../styles/mixins';
import { widths } from '../styles/variables';
import reddit from '../images/reddit.png';
import twitter from '../images/twitter.png';

const StyledFooterBlock = styled.div`
  background-color: ${p => p.theme.colors.black};
  width: 100%;
  display: block;
  padding-top: 0.5em;
`;

const StyledFooterBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1em;
`;

const StyledDisclaimerHeading = styled.h1`
  color: ${p => p.theme.colors.white};
  padding-bottom: 0.15em;
`;

const StyledDisclaimerParagraph = styled.p`
  color: ${p => p.theme.colors.white};
  opacity: 0.85;
  font-size: 0.75em;
  margin-bottom: 1rem;
`;

const StyledDisclaimerSmallerParagraph = styled.p`
  color: ${p => p.theme.colors.white};
  opacity: 0.85;
  font-size: 0.7em;
  margin-bottom: 1rem;
`;

const StyledBuyMeACoffeeImage = styled.img`
  height: 60px !important;
  width: 217px !important;
  margin-top: 15px;
`;

const StyledSupportBlockContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${getEmSize(widths.lg)}em;
  padding: 2em 2em;
  color: ${p => p.theme.colors.white};
  text-align: center;
`;

const StyledSupportBlock = styled.div`
  color: ${p => p.theme.colors.black};
  width: 100%;
  max-width: 1200px;
  display: block;
  padding-top: 0.5em;
  margin: auto;
  margin-bottom: 80px;
  z-index: 1;
`;

const StyledSupportBlockInner = styled.div`
  color: ${p => p.theme.colors.black};
  width: 80%;
  display: block;
  padding-bottom: 1em;
  margin-left: 10%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 50px;
  background: ${p => p.theme.colors.card};
  border-radius: 5px;
`;

const StyledSupportBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledSupportHeader = styled.h2`
  padding-bottom: 15px;
`;

const StyledGradientContainer = styled.div`
  position: relative;
`;

const StyledGradient = styled.div`
  background: ${p => p.theme.colors.gradient};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 0;
  pointer-events: none;
  transition: background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad);
`;

const StyledSubredditLink = styled.a`
  white-space: nowrap;
  font-size: 1.15em;
  padding: 8px 8px;
  img {
    vertical-align: middle;
    margin: 4px;
  }
`;

const Footer: React.FC = () => (
  <>
    <StyledSupportBlock>
      <StyledSupportBlockInner>
        <StyledSupportBlockContent>
          <StyledSupportBlockContainer>
            <StyledSupportHeader>Support &amp; Feedback</StyledSupportHeader>
            <p>Got feedback or suggestions for more awesomeness? Or wanna keep up to date with changes?</p>
            <p>
              <StyledSubredditLink href="https://twitter.com/wsbdaily">
                <img src={twitter} width={24} height={24} alt="@wsbdaily" />
                @wsbdaily
              </StyledSubredditLink>
              <StyledSubredditLink href="https://www.reddit.com/r/wsbdaily">
                <img src={reddit} width={24} height={24} alt="wsbdaily subreddit" />
                wsbdaily
              </StyledSubredditLink>
            </p>
            <p>No obligation, if you'd like to support us with development and hosting costs then</p>
            <a href="https://www.buymeacoffee.com/wsbdaily" target="_blank" rel="noreferrer">
              <StyledBuyMeACoffeeImage src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" />
            </a>
          </StyledSupportBlockContainer>
        </StyledSupportBlockContent>
      </StyledSupportBlockInner>
    </StyledSupportBlock>
    <StyledGradientContainer>
      <StyledGradient />
    </StyledGradientContainer>
    <StyledFooterBlock>
      <StyledFooterBlockContent>
        <Container>
          <StyledDisclaimerHeading>Disclaimer</StyledDisclaimerHeading>
          <StyledDisclaimerParagraph>
            WE ARE NOT AFFILIATED WITH REDDIT, THE LEADERSHIP OR MODERATOR TEAMS OF ANY OF THE SUBREDDITS WE CRAWL NOR WITH ANY OTHER
            WEBSITE, COMPANY OR DATA PROVIDER FROM WHICH WE PULL AND/OR AGGREGATE DATA FROM.
          </StyledDisclaimerParagraph>
          <StyledDisclaimerParagraph>
            ALL CONTENT ON THIS SITE IS SOLEY FOR ENTERTAINMENT AND/OR INFORMATIONAL PURPOSES ONLY. THE SITE AND CONTENT, ARE PROVIDED “AS
            IS” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. ANY CONTENT OR STATEMENT HEREIN SHOULD NOT BE CONSTRUED AS INVESTMENT
            ADVICE, OR AS AN OFFER TO BUY, SELL, OR SOLICIT THE PURCHASE OR SALE OF ANY SECURITY OR FINANCIAL INSTRUMENT. DO YOUR OWN DUE
            DILIGENCE.
          </StyledDisclaimerParagraph>
          <StyledDisclaimerParagraph>
            THE INFORMATION ON THIS SITE IS NOT UPDATED IN REAL-TIME, EACH DATASET SPECIFIES WHEN IT WAS “LAST UPDATED”. MOST INFORMATION IS
            CURRENTLY INDEPENDENTLY PERIODICALLY UPDATED EVERY 30 TO 150 MINUTES AND ONLY WHEN THE MARKETS ARE CLOSED. YOU BEAR ALL RISKS
            ASSOCIATED WITH THE USE OF THE SITE AND CONTENT, INCLUDING WITHOUT LIMITATION, ANY RELIANCE ON THE ACCURACY, COMPLETENESS OR
            USEFULNESS OF ANY CONTENT AVAILABLE ON THE SITE. YOUR USE OF THE INFORMATION CONTAINED HEREIN IS SOLELY AT YOUR OWN RISK. DO
            YOUR OWN DUE DILIGENCE.
          </StyledDisclaimerParagraph>
          <StyledDisclaimerSmallerParagraph>
            There are high risks associated with investing in securities. Investing in stocks, options, bonds, ETFs, mutual funds, and money
            market funds involve risk of substantial loss. Loss of principal is possible. Some high risk investments may use leverage, which
            will greatly accentuate any losses. Foreign investing involves special risks, including a greater volatility and political,
            economic and currency risks and differences in accounting methods. Any past investment performance is not a guarantee or
            predictor of future investment performance. DO YOUR OWN DUE DILIGENCE.
          </StyledDisclaimerSmallerParagraph>
          <StyledDisclaimerSmallerParagraph>
            The software providing the content on this site is currently in an alpha state. Although care is taken to publish accurate data
            inaccurate information is very, very possible. Use at your own risk. DO YOUR OWN DUE DILIGENCE.
          </StyledDisclaimerSmallerParagraph>
        </Container>
      </StyledFooterBlockContent>
    </StyledFooterBlock>
  </>
);

export default Footer;
