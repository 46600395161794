import * as React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';
import normalize from '../styles/normalize';

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.colors.background};
  transition: ${p => p.theme.colorModeTransition};
  min-height: 100vh;
`;

interface LayoutRootProps {
  children?: React.ReactNode;
  className?: string;
}

function LayoutRoot({ children, className }: LayoutRootProps) {
  const [colorMode] = useColorMode();

  React.useEffect(() => {
    parent.postMessage({ theme: colorMode }, '*');
  }, [colorMode]);

  return (
    <>
      <Global styles={normalize} />
      <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
    </>
  );
}

export default LayoutRoot;
