import * as React from 'react';
import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { Link } from 'gatsby';

import { heights, dimensions, colors } from '../styles/variables';
import Container from './Container';

// const StyledHeader = styled.header`
//   height: ${heights.header}px;
//   padding: 0 ${dimensions.containerPadding}rem;
//   background-color: ${colors.brand};
//   color: ${transparentize(0.5, colors.white)};
// `;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const StyledHeaderDisclaimer = styled.div`
  background-color: #000;
  color: #fafafa;
  width: 100%;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 0.8em;
  padding: 0.5em;
`;

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => (
  // <StyledHeader>
  <>
    {/* <StyledHeaderDisclaimer>
      *** NOT INVESTMENT ADVICE: ALL CONTENT IS PROVIDED 'AS IS' FOR ENTERTAINMENT AND/OR INFORMATIONAL PURPOSES ONLY ***
      <br />
      *** WITHOUT WARRANTY OF ANY KIND. YOU BEAR ALL RISKS, INCLUDING ANY RELIANCE ON THE ACCURACY OF ANY INFORMATION. ***
      <br />
      *** WE ARE NOT AFFILIATED WITH THE WALLSTREETBETS SUBREDDIT ON REDDIT ***
    </StyledHeaderDisclaimer> */}
  </>
  //   <HeaderInner>
  //     <HomepageLink to="/">{title}</HomepageLink>
  //   </HeaderInner>
  // </StyledHeader>
);

export default Header;
